import styled from "styled-components";

export const AboutWrapper = styled.section`

    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: center !important; 

.about-content{
    width:95%;
    height: 100%;
    background-size: cover !important;
    background-position: center !important; 
    border-bottom-right-radius: 20px;
    position: relative;
    left: -12px;
    top: -40px;
}

`

export const AboutValueWrapper = styled.section` 
.common-space{
    padding:50px 0;
}
.visionRow{
    padding-top: 30px;
}
.visionBox{
    padding-right: 40px
}
.vision-img{
    border-radius: 25px;
}
.bird-wrap{
    & span {
        position:relative;
        width: 100%;
    }
}
.bird{
    position: absolute;
    bottom: -100px;
    left: 200px;
}
`