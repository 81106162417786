import styled from "styled-components";

export const BannerWrapper = styled.section`
    width: 100%;
    max-height: 100vh;
    min-height:600px;
    background-size: cover !important;
    background-position: center !important;
    .header-main {
        position: relative;
    }
    
    .banner-layers {
        position: relative;
        height: 100vh;
        min-height:600px;
        // margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // overflow: hidden;
    }
    .down-btn {
        // position: relative;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10;
        width: 13px;
        bottom: -10px;
        & a {
            // position: absolute;
            // left: 0;
            // right: 0;
            // margin: auto;
            // z-index: 10;
            // width: 90px;
            // bottom: -20px;
        }
    }
    
    .animated-container{
        position:relative;
        z-index:2;
    }
    .animated-wrapper{
        position: relative;
        bottom: 35px;
        left: -20px;
        display: flex;
        height:210px;
        flex-direction: row-reverse;
        // justify-content: flex-end;
    }
    .animated-items{
        position:relative;
        display: inline-flex;
        flex-direction: column;
    }
    .tested{
        // justify-content: flex-end;
    }
    .leafs{
        position: relative;
        bottom: 30px;
        left: 34px;
    }
    .main-deyam{
        overflow:hidden;
        position: relative;
        top: 32px;
        & img{
            overflow:hidden;
            // animation: main-deyam 2s ease 4000ms backwards
            animation: an 1s ease-out 4000ms both;
        }
    }
    @keyframes an{
        from{
            opacity: 0;
            transform: perspective(500px) translate3d(-35px, 40px, -150px) rotate3d(1, -1, 0, 30deg);
        }
        to{
            opacity: 1;
            transform: perspective(500px) translate3d(0, 0, 0);
        }
    }

    .green-leaf{
        overflow:hidden;
        animation: green-leaf 2s ease 2500ms backwards
    }

    @keyframes green-leaf {
        0% {
        height:0px;
        }
        100% {
        height: 150px;
        }
    }
    .white-leaf{
        overflow: hidden;
        position: absolute;
        top: 75px;
        right: 20px;
        animation: white-leaf 2s ease 3100ms backwards;
    }

    @keyframes white-leaf {
        0% {
            height: 0;
        }
        100% {
            height:169px;
        }
    }
    
.animated{
    overflow: hidden;
    animation: animated 2s ease 4500ms backwards
}

@keyframes animated {
    from {
        width: 0;
        // height:72px;
      }
      to {
        // height:72px;
        width: 100%;
      }
}

.taglineAnimContainer{
    position: relative;
    left: -3px;
    top: -23px;
    z-index: 5;
}
.taglineAnim{
    display: flex;
    justify-content: flex-start;
}

.animatedTag{
    overflow: hidden;
    animation: animatedTag 2s ease 4500ms backwards;
    // animation-delay: 5s;
}

@keyframes animatedTag {
    from {
        width: 0;
        // height:72px;
      }
      to {
        // height:72px;
        width: 100%;
      }
}

.top-leaf{
    position: absolute;
    top: -70px;
    left: -73px;
    z-index: 200;
}

.bottom-leaf{
    position: absolute;
    bottom: 10px;
    right: -30px;
}

.down-btn{
    [fill] {
        fill: var(--white);
    }
}


`