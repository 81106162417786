import React from 'react'
import { FooterWapper } from './Styled'
import { Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, SpanStyled } from '../Styled/Elements'
import { Logo } from '../Styled/AllImages'
import { NavDataConfig } from '../data/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t, i18n } = useTranslation();
    // const isRTL = i18n.dir() === 'rtl';
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    return (
        <FooterWapper className='container-fluid'>
            <Container>
                <Row>
                    <Box className='d-flex align-items-center justify-content-between gap-2 footer-content'>
                        <Box className='footer-logo'>
                            <Logo />
                        </Box>
                        <Box className='d-flex flex-column justify-content-center gap-2'>
                            <Box className='footer-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to}>{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='footer-revised'>
                                <SpanStyled className='text-center' size='13px' color='var(--text)' transform='capitalize'>{t('AllRightReserved')} &copy; {date_year.getFullYear()} </SpanStyled>
                                <SpanStyled className='text-center' size='13px' color='var(--text)' transform='capitalize'>
                                    <SpanStyled className='fowl' color='var(--lightgreen)' size='14px' weight='bold'> {t('Deyam_LandScape')} </SpanStyled>
                                </SpanStyled>
                                <HeadingStyled className='text-center' size='13px' color='var(--text)' transform='capitalize'>{t('Developed_by')}
                                    <SpanStyled className='fowl' color='var(--lightgreen)' size='14px' weight='bold' onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
                                </HeadingStyled>
                            </Box>
                        </Box>
                        {/* <FooterImg /> */}
                        <Logo className='visibility' />
                        {/* {isRTL ? <FooterArabicImg /> : < FooterImg />} */}
                    </Box>
                </Row>
            </Container>
        </FooterWapper>
    )
}

export default Footer