import React from 'react'
import { AboutWrapper } from './Styled'
import AboutValues from './AboutValues'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { AboutBg, AboutBg1, AboutImg1, AboutImg2, BTNICON } from '../../Styled/AllImages'


const About = () => {
    const { t, i18n } = useTranslation();

    const WhatsApp = () => {
        const phoneNumber = '+966538343390';
        const message = 'Hello, deyam Landscape ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <AboutWrapper style={{ background: `url(${AboutBg})` }}>
            <Container fluid id='about'>
                <Row>
                    <Col>
                        <Box className='common-space about-content mb-0' style={{ background: `url(${AboutBg1})` }} >
                            <Container>
                                <Row className='align-items-center my-3'>
                                    <Col lg={8} md={12}>
                                        <Box>
                                            <MainHeading className=' main-heading mb-3' color='var(--text)'>{t('About_us')}</MainHeading>
                                            <TextStyled color='var(--text)'>
                                                <SpanStyled color='var(--lightgreen)' > {t('SOGUF_GROUP')} </SpanStyled> {t('about_heading')}</TextStyled>
                                        </Box>
                                    </Col>
                                    <Col lg={4} md={12}>
                                        <Box className='text-end'>
                                            <LazyImage className='layer-mid-3' src={AboutImg1} />
                                        </Box>
                                    </Col>
                                </Row>
                                <Row className='founder my-3'>
                                    {/* <Col lg={4} md={12}>
                                        <Box className='text-start'>
                                            <LazyImage className='layer-mid-3' src={AboutImg2} />
                                        </Box>
                                    </Col> */}
                                    <Col md={12}>
                                        <Box>
                                            <MainHeading className=' main-heading mb-3' color='var(--text)'>{t('Founder_Message')}</MainHeading>
                                            <TextStyled color='var(--text)'> {t('at')}
                                                <SpanStyled color='var(--lightgreen)' > {t('Deyam')}, </SpanStyled>
                                                {t('Founder_Message_content')}
                                            </TextStyled>
                                            <ContactButton className='button1' icon={BTNICON} label={t('button')} onClick={WhatsApp} />
                                        </Box>
                                    </Col>
                                </Row>
                            </Container>
                        </Box>
                    </Col>
                </Row>
            </Container >
            <AboutValues />
        </AboutWrapper >
    )
}

export default About