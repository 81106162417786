import styled from "styled-components";

export const ClientWrapper = styled.section`

.client-wrap{
    display: grid;
    grid-template-columns: 1fr  1fr 1fr 1fr 1fr;
    gap: 18px;
    // padding: 10px;
}
.client-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.item-1 {
    grid-column: 3 / 5;
  }


`
