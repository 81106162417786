import React from 'react'
import ContactForm from './ContactForm'
import Footer from '../../footer/Footer'
import { ContactWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { TextWithIcon } from '../../Styled/CustomElements'
import { Box, HeadingStyled, LazyImage, MainHeading, Span, } from '../../Styled/Elements'
import { ContactBg, ContactImg, Email, Instagram, Linkdin, Location, Phone, Tiktok, Twitter, Youtube } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

const Contact = () => {

  const Map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.168329822841!2d46.60544857482546!3d24.82391644671983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee521402d040d%3A0x74436b6b72ee602c!2sDeyamLandscape!5e0!3m2!1sen!2s!4v1710757211665!5m2!1sen!2s"
  const { t } = useTranslation();
  const socialIcon = [
    {
      img: <Twitter />,
      link: 'https://twitter.com/deyamlandscape',
    },
    {
      img: <Tiktok />,
      link: 'https://www.tiktok.com/@deyamlandscape',
    },
    {
      img: <Linkdin />,
      link: 'https://www.linkedin.com/company/deyam-landscape-real-estate',
    },
    {
      img: <Instagram />,
      link: 'https://www.instagram.com/deyamlandscape',
    },
    {
      img: <Youtube />,
      link: 'https://www.youtube.com/@Deyamlandscape',
    },
    // {
    //   img: <Linkdin />,
    //   link: 'https://www.linkedin.com/company/deyam-landscape-real-estate',
    // },

    // {
    //     img: <Instagram />,
    //     link: 'https://www.tiktok.com/@deyamlandscape',
    // },

  ];
  return (
    <ContactWrapper >
      <Container className='bg-setting common-space-over' style={{ background: `url(${ContactBg})` }}  >
        <Row>
          <Col>
            <Box className='contact-box'>
              <iframe src={Map} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Box>
          </Col>
        </Row>
        <Box id='contact'>
          <Row>
            <Box className='d-flex align-items-center justify-content-center my-3 text-center'>
              <MainHeading className='main-heading' color='var(--text)'>{t('Contact_Us')}</MainHeading>
            </Box>
          </Row>
          <Row>
            <Col>
              <Box className='contact-wrapper'  >
                <Box className='contect-with-us'>
                  <ContactForm />
                </Box>
                <Box className='contect-with-you'>
                  <Box className='d-flex flex-column gap-3'>

                    <Box className='contact-info'>
                      <HeadingStyled className='mb-3' color='var(--text)' transform='math-auto'> {t('Email_Us_on')}</HeadingStyled>
                      <TextWithIcon headingSize='14px' className='contact-text' icon={Email} title='sales@deyamlandscape.com <br/> info@deyamlandscape.com' />
                    </Box>
                    <Box className='contact-info'>
                      <HeadingStyled className='mb-3' color='var(--text)' transform='math-auto'>{t('Call_Us_on')}</HeadingStyled>
                      <TextWithIcon headingSize='14px' className='contact-text' icon={Phone} title='+966538343390' />
                    </Box>
                    {/* <Box className='contact-info'>
                      <HeadingStyled className='mb-3' color='var(--lightgreen)' transform='math-auto'>{t('Meet_Us_on')} </HeadingStyled>
                      <TextWithIcon headingSize='14px' className='contact-text' icon={Location} title={t('location')} />
                    </Box> */}
                    <Box className='follow'>
                      <HeadingStyled className=' mb-3' color='var(--text)' transform='math-auto'>{t('Follow_Us_on')}</HeadingStyled>
                      <Box className='d-flex align-items-center gap-3'>
                        {
                          socialIcon.map((list, key) => (
                            <Box key={key} className='contact-links'>
                              <a href={list.link} target="_blank" rel="noopener noreferrer">
                                {list.img}
                              </a>
                            </Box>
                          ))
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
      <Span className='contact-img'>
        <LazyImage src={ContactImg} />
      </Span>
      <Footer />
    </ContactWrapper >
  )
}

export default Contact