import styled from 'styled-components'
import { Box, HeadingStyled, Span, SpanStyled, TextStyled } from './Elements';


export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}

export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className='d-flex align-items-center'>
            <button {...sliceProps(props, unwantedProps)} className={`header-btn ${!props.icon ? 'Btn-icon' : ''} `} autoComplete='false'>
                {props.icon && (
                    <props.icon width='20px' />
                )}
                <SpanStyled color='var(--WhiteColor)' size='14px'>{props.label ? props.label : ''}</SpanStyled>
            </button>
        </ButtonWrapper>
    )
}

export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)}>
            <button {...sliceProps(props, unwantedProps)} className={`click-btn gap-3 ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <span>{props.label ? props.label : ''}</span>
                {props.icon && (
                    <props.icon width='22px' />
                )}
            </button>
        </ContactWrapper>
    )
}
export const ContactButton2 = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper2 {...sliceProps(props, unwantedProps)}>
            <button {...sliceProps(props, unwantedProps)} className={`click-btn gap-3 ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <span>{props.label ? props.label : ''}</span>
                {props.icon && (
                    <props.icon width='22px' />
                )}
            </button>
        </ContactWrapper2>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute input-svg' />
            )}
        </InputStyledWrap>
    )
}
export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex align-items-start `}>
        <Box className='mx-2 pt-1 d-flex align-items-center justify-content-center contact-svg'>
            <props.icon width='20px' height='20px' />
        </Box>
        <Box>
            <Box className='mb-0 contact-text' size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--text)' : 'var(--text)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </Box>
            {props.desc ?
                <TextStyled className='text-uppercase' color={'var(--text)'} size='14px' >
                    {props?.desc}
                </TextStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)

export const StepNumbers = (props) => {
    const unwantedProps = ['number', "stepTxt", "class"];
    return (
        <StepNumbersWrap className={`stepNumbersWrap ${props.class ? props.class : '' }`} {...sliceProps(props, unwantedProps)} >
            <Box className='StepNumbers'>
                {props.number}
            </Box>
            <Box className='StepNumbersTxt'>
                {props.stepTxt}
            </Box>
        </StepNumbersWrap>
    )
}
const StepNumbersWrap = styled.div`
    display: flex;
    align-items: center;
    color: var(--themeColor);
    position: relative;
    z-index: 1;
    
    .StepNumbers{
        display: flex;
        font-size: 110px;
        font-family: var(--regular);
        font-weight: 700;
        line-height: 90px;
    }
    .StepNumbersTxt{
        display: flex;
        font-size: 20px;
        font-family: var(--regular);
        font-weight: 700;
        line-height: 25px;
    }
`

const TextWithStyledWrapper = styled.div`
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`
export const InputStyled = styled.input`
    background:var(--bgColor);
    border: none;
    border-radius: 8px;
    height: 43px;
    // color: var(--textGrey);
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 14px;
        color: var(--textPalceholder);
    }
`
export const InputStyledWrap = styled.div`
    border-radius: 8px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`


export const ContactWrapper = styled.span`
button{
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ''};
    gap: 6px;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background: var(--text);
    color: var(--themeColor);
    text-transform: capitalize;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    transition: all 0.4s ease-in-out;
    & > span{
        color: var(--themeColor);
        font-family: var(--regular);
        text-transform: uppercase;
        font-weight: 500;
    }
     svg{
        [fill]{
            transition: all 0.4s ease-in-out;
        }
    }
    &:hover{
        background: var(--lightgreen);
        color: var(--white);
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
        svg{
            [fill]{
                fill:var(--white);
            }
        }
        & > div{
            color: var(--whiteColor);
        }
    }
}

    
    
`
export const ContactWrapper2 = styled.span`

button{
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ''};
    gap: 6px;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background: var(--themeColor) !important;
    font-family: var(--regular);
    color: var(--whiteColor);
    text-transform: capitalize;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
    transition: all 0.4s ease-in-out;
    & > span{
        color: #fff;
        font-family: var(--regular);
        text-transform: uppercase;
        font-weight: 500;
    }
    svg{
        [fill]{
            transition: all 0.4s ease-in-out;
            fill:var(--lightgreen) !important;
        }
    }
    &:hover{
        background: var(--lightgreen) !important;
        color: var(--white) !important;
        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.3);
        svg{
            [fill]{
                fill:var(--themeColor) !important;
            }
        }
    }
}
    
    
`
export const ButtonWrapper = styled.div`
button,
button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

button {
    background: var(--text);
    border: 1px solid var(--text);
    border-radius: 8px;
    color: var(--themeColor);
    display: flex;
    gap:8px;
    font-size: 14px;
    padding: 10px 14px;
    position: relative;
    text-transform: uppercase;

}

button::before,
button::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--lightgreen);
    box-shadow: 1px 2px 3px #7271719e;
}
button::before{
    background: transparent;
}

button:hover {
    border: 1px solid var(--lightgreen);
    color: var(--themeColor);
    background: var(--lightgreen);
}
.header-btn svg {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    [fill] {
        fill: var(--white);
    }
}
.header-btn svg {
    [fill] {
        fill: var(--themeColor);
    }
}

  
`