
import About from "../components/sections/about/About"
import Banner from "../components/sections/banner/Banner"
import Client from "../components/sections/client/Client"
import Contact from "../components/sections/contact/Contact"
import Service from "../components/sections/services/Service"
import Projects from "../components/sections/project/Projects"
import ProcessSteps from "../components/sections/processSteps/ProcessSteps"
import ProjectGallery from "../components/sections/project/ProjectGallery"

const Index = () => {
    return (
        <>
            <Banner />
            <About />
            <Service />
            {/* <Projects /> */}
            <ProjectGallery/>
            <ProcessSteps />
            <Client />
        </>
    )
}

export default Index