import { Link } from 'react-scroll'
import React from 'react'
import { BannerWrapper } from './Styled'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, Span } from '../../Styled/Elements'
import { BannerBg, BottomLeaf, Dots, DownArrow, GreenLeaf, Instagram, LightLogo, Linkdin, MainDeyam, Taglines, TextMain, Tiktok, TopLeaf, Twitter, WhiteLeaf, Youtube, whiteLeaf } from '../../Styled/AllImages'

const Banner = () => {
    const { t } = useTranslation();
    // useEffect(() => {
    //     window.addEventListener('scroll', () => {
    //         let scrollY = window.scrollY;
    //         document.documentElement.style.setProperty("--scrollTop", `${scrollY}px`);
    //     });
    // }, []);

    const socialIcon = [
        {
            img: <Twitter />,
            link: 'https://twitter.com/deyamlandscape',
        },

        {
            img: <Tiktok />,
            link: 'https://www.tiktok.com/@deyamlandscape',
        },
        {
            img: <Linkdin />,
            link: 'https://www.linkedin.com/company/deyam-landscape-real-estate',
        },
        {
            img: <Instagram />,
            link: 'https://www.instagram.com/deyamlandscape',
        },
        {
            img: <Youtube />,
            link: 'https://www.youtube.com/@Deyamlandscape',
        },

    ];
    return (
        <BannerWrapper className='' id='home' style={{ background: `url(${BannerBg})` }}>
            <Row>
                <Col className='position-relative'>
                    <Box className='banner-layers'>
                        <Box className='animated-container'>
                            {/* <Span className='light-logo'>  <img src={LightLogo} alt="" />  </Span> */}
                            <img className=' top-leaf' src={TopLeaf} alt="" />
                            <Box className='animated-wrapper'>
                                <Box className='animated-items leafs'>
                                    <Span className=' green-leaf' > <img src={GreenLeaf} alt="" /> </Span>
                                    <Span className=' white-leaf'><img src={whiteLeaf} alt="" /></Span>
                                </Box>
                                <Box className='animated-items tested'>
                                    <Span className=' main-deyam' > <img src={MainDeyam} alt="" /> </Span>
                                    <Span className=' animated dots' > <img src={Dots} alt="" /> </Span>
                                    <Span className='animated text-main'> <img src={TextMain} alt="" /> </Span>

                                </Box>

                            </Box>
                            <Box className='taglineAnimContainer'>
                                <Span className='animatedTag taglineAnim'> <img src={Taglines} alt="" /> </Span>
                            </Box>
                            <img className='bottom-leaf ' src={BottomLeaf} alt="" />
                        </Box>
                    </Box>
                    <Box className='down-btn'>
                        <Link to='about' spy={true} smooth={true} offset={-40} duration={500} activeClass="active">
                            <DownArrow />
                        </Link>
                    </Box>
                    <Box className='social-link'>
                        {
                            socialIcon.map((list, key) => (
                                <Box key={key}>
                                    <a href={list.link} target="_blank" rel="noopener noreferrer">
                                        {list.img}
                                    </a>
                                </Box>
                            ))
                        }
                    </Box>
                </Col>
            </Row>
        </BannerWrapper >
    )
}

export default Banner