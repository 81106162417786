import styled from "styled-components";

export const ServiceWrapper = styled.section`
.service-list{
    display:flex;
    // align-items:center;
    gap:8px;
    margin:15px 0;
}
.service-tree span{
    width:100%
}
`
export const AchivementWrapper = styled.section`

`