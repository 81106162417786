import React from 'react'

import { ClientWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'

import { Box, LazyImage, MainHeading, TextStyled } from '../../Styled/Elements'
import { ProjectBg, Client1, Client2, Client3, Client4, Client5, Client6, Client7, Client8, Client9, ClientBg } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import Contact from '../contact/Contact'
import GalleryComponent from '../gallery/Gallery'

const Client = () => {
    const { t } = useTranslation();

    return (
        <ClientWrapper className='common-space bg-setting' style={{ background: `url(${ProjectBg})` }}>
            <GalleryComponent />
            <Container id='Client'>
                <Row>
                    <Col>
                        <Box className='d-flex align-items-center justify-content-center common-space'>
                            <MainHeading className='main-heading' color='var(--text)'>{t('Our_Client')}</MainHeading>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Box className='client-wrap'>
                        <Box className='client-bg client-content' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client1} />
                        </Box>
                        <Box className='client-bg client-content' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client2} />
                        </Box>
                        <Box className='client-bg client-content' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client3} />
                        </Box>
                        <Box className='client-bg client-content' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client4} />
                        </Box>
                        <Box className='client-bg client-content' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client5} />
                        </Box>
                        <Box className='client-bg client-content' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client6} />
                        </Box>
                        <Box className='client-bg client-content' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client7} />
                        </Box>
                        <Box className='client-bg client-content item-1' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client8} />
                        </Box>
                        <Box className='client-bg client-content' style={{ background: `url(${ClientBg})` }}>
                            <LazyImage src={Client9} />
                        </Box>
                    </Box>
                </Row>
            </Container>
            <Contact />
        </ClientWrapper>
    )
}

export default Client