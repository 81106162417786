import { projectsTabs, tabs } from '../../data/Data'
import { GalleryWrapper, ProjectGalleryWrapper } from './Styled'
import React, { useEffect, useState, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import FsLightbox from "fslightbox-react";
import { DownArrow, LeftArrow, ProjectBg, RightArrow } from '../../Styled/AllImages'

const ProjectGallery = () => {
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [toggler, setToggler] = useState(false);
    const [clickedImageIndex, setClickedImageIndex] = useState();
    const handleImageClick = (tabIndex, imageIndex) => {
        setScrollPosition(window.pageYOffset);
        setToggler(!toggler); // Toggle the lightbox to open
        setClickedImageIndex(imageIndex); // Store the clicked image index
    }
    const handleSlideChange = () => {
        const currentSlide = tabs[activeItem].list[clickedImageIndex];
        if (typeof currentSlide === 'string' && currentSlide.endsWith('.mp4')) {
            const videoElement = document.querySelector('video');
            if (videoElement) {
                videoElement.pause();
            }
        }
    };
    const toggleItem = (index) => {
        // setActiveItem(activeItem === index ? null : index);
        setActiveItem(activeItem === index ? activeItem : index);
    };
    const closeGallery = () => {
    
        // Restore the scroll position
        window.scrollTo(0, scrollPosition);
    };


    const tabRef = useRef(null);
    const [scrollDirection, setScrollDirection] = useState(null);

    const scrollTab = (direction) => {
        setScrollDirection(direction);
        const container = tabRef.current;
        const scrollAmount = container.offsetWidth; // Assuming each tab item has the same width

        if (direction === 'left') {
            container.scrollBy({
                left: -scrollAmount,
                behavior: 'smooth'
            });
        } else {
            container.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });
        }
    };



    return (
        <ProjectGalleryWrapper className='common-space bg-setting' id='projects' style={{ background: `url(${ProjectBg})` }}>
            <Container>
                <Row>
                    <Col>
                        <Box className='d-flex align-items-center justify-content-center my-4'>
                            <MainHeading className='main-heading' color='var(--text)'>{t('Project')}</MainHeading>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Box className='py-3 tab-container common-space'>
                            <LeftArrow className='arrows' size='30px' onClick={() => scrollTab('left')} />
                            <Box className='tab-content' ref={tabRef}>
                                {
                                    projectsTabs.map((list, index) => (
                                        <li key={index} className={`tabs-item  ${activeItem === index ? 'active' : ''}`} onClick={() => toggleItem(index)}>
                                            <SpanStyled className="px-2" size='16px' transform='math-auto'>{t(list.title)}</SpanStyled>
                                        </li>
                                    ))
                                }
                            </Box>
                            <RightArrow className='arrows' size='30px' onClick={() => scrollTab('right')} />
                        </Box>
                        <Box className={`position-relative common-space pb-4`}>
                            {projectsTabs.map((list, index) => (
                                <Box key={index} className={`${activeItem === index ? '' : ''}`} >
                                    {activeItem === index && (
                                        <Row>
                                            {list.list.slice(0, 4).map((item, itemIndex) => (
                                                <Col lg={3} md={6} sm={12}>
                                                    <Box key={itemIndex} className='Tab-box' >
                                                        <LazyImage src={item} onClick={() => handleImageClick(index, itemIndex)} />
                                                    </Box>
                                                </Col>
                                            ))}
                                            {list.list.slice(4, 5).map((item, itemIndex) => (
                                                <Col lg={6} md={12} key={itemIndex + 4}>
                                                    <Box className={`Tab-box `}>
                                                        <LazyImage src={item} onClick={() => handleImageClick(index, itemIndex + 4)} />
                                                    </Box>
                                                </Col>
                                            ))}
                                            {list.list.slice(5, 6).map((item, itemIndex) => (
                                                // Render the last column separately with the conditional class
                                                itemIndex === list.list.slice(5, 6).length - 1 && (
                                                    <Col lg={6} md={12} key={itemIndex + 5}>
                                                        <Box className={`Tab-box ${activeItem === index ? `video-thumb tab${index}-active` : `tab${index}-inactive`}`}>
                                                            <LazyImage src={item} onClick={() => handleImageClick(index, itemIndex + 5)} />
                                                        </Box>
                                                    </Col>
                                                )
                                            ))}


                                            <FsLightbox
                                                toggler={toggler}
                                                sources={list.list}
                                                // sourceIndex={index}
                                                // sources={tabs[activeItem].list} // Use the sources of the active tab
                                                sourceIndex={clickedImageIndex}
                                                onSlideChange={handleSlideChange}
                                                onClose={closeGallery}

                                            />

                                        </Row>


                                    )}

                                </Box>
                            ))}

                        </Box>
                    </Col>
                </Row>
            </Container>
            <Box className='down-btn down-btn-all down-btn-projects'>
                <DownArrow className='arrow-down' />
            </Box>
        </ProjectGalleryWrapper >

    )
}
export default ProjectGallery
