import { tabs } from '../../data/Data'
import { GalleryWrapper } from './Styled'
import React, { useEffect, useState, } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import FsLightbox from "fslightbox-react";
import { Breathing, Image, Shimmer } from 'react-shimmer'



const GalleryComponent = () => {
    const { t } = useTranslation();
    const [activeItem, setActiveItem] = useState(0);
    const toggleItem = (index) => {
        // setActiveItem(activeItem === index ? null : index);
        setActiveItem(activeItem === index ? activeItem : index);
    };
    // const [scrollDirection, setScrollDirection] = useState(null);
    // const scrollTab = (direction) => {
    //     setScrollDirection(direction);
    //     const container = tabRef.current;
    //     const scrollAmount = 200;

    //     if (direction === 'left') {
    //         container.scrollLeft -= scrollAmount;
    //     } else {
    //         container.scrollLeft += scrollAmount;
    //     }
    // };
    const [scrollPosition, setScrollPosition] = useState(0);
    const [toggler, setToggler] = useState(false);
    const [clickedImageIndex, setClickedImageIndex] = useState();
    const handleImageClick = (tabIndex, imageIndex) => {
        setScrollPosition(window.pageYOffset);
        setToggler(!toggler); // Toggle the lightbox to open
        setClickedImageIndex(imageIndex); // Store the clicked image index
    }
    const handleSlideChange = () => {
        const currentSlide = tabs[activeItem].list[clickedImageIndex];
        if (typeof currentSlide === 'string' && currentSlide.endsWith('.mp4')) {
            const videoElement = document.querySelector('video');
            if (videoElement) {
                videoElement.pause();
            }
        }
    };
    const closeGallery = () => {
    
        // Restore the scroll position
        window.scrollTo(0, scrollPosition);
    };

    return (
        <GalleryWrapper id='gallery'>
            <Container>
                <Row>
                    <Col>
                        <Box className='common-space d-flex flex-column align-items-center justify-content-center gap-3'>
                            <MainHeading className='main-heading ' color='var(--text)'>{t('Our_Nurseries')} </MainHeading>
                            <TextStyled className='text-center' color='var(--text)'> {t('Our_Nurseries_content')} <SpanStyled color='var(--lightgreen)'> {t('millions')} </SpanStyled> {t('majer')} </TextStyled>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* <Box className='py-3 tab-container common-space'>
                            <LeftArrow className='arrows' size='30px' onClick={() => scrollTab('left')} />
                            <Box className='tab-content'>
                                {
                                    tabs.map((list, index) => (
                                        <li key={index} className={`tabs-item  ${activeItem === index ? 'active' : ''}`} onClick={() => toggleItem(index)}>
                                            <SpanStyled className="px-2" size='16px' transform='math-auto'>{t(list.title)}</SpanStyled>
                                        </li>
                                    ))
                                }
                            </Box>
                            <RightArrow className='arrows' size='30px' onClick={() => scrollTab('right')} />
                        </Box> */}
                        <Box className={`position-relative common-space pt-0`}>
                            {tabs.map((list, index) => (
                                <Box key={index} className={`${activeItem === index ? '' : ''}`} >

                                    <Row>
                                        {list.list.slice(0, 4).map((item, itemIndex) => (
                                            <Col lg={3} md={6} sm={12}>
                                                <Box key={itemIndex} className='Tab-box' >
                                                    <LazyImage src={item} onClick={() => handleImageClick(index, itemIndex)} />
                                                    {/* {isLoading ? <p className='loading-btn'>Loading...</p> : null} */}

                                                    {/* <Image src={item} fallback={<Breathing height={190} />} onClick={() => handleImageClick(index, itemIndex)} /> */}
                                                </Box>
                                            </Col>
                                        ))}
                                        {list.list.slice(4, 6).map((item, itemIndex) => (
                                            <Col lg={6} md={12} key={itemIndex + 4}>
                                                <Box className={`Tab-box ${itemIndex === list.list.slice(4, 6).length - 1 ? 'video-thumb video' : ''}`}>
                                                    <LazyImage src={item} onClick={() => handleImageClick(index, itemIndex + 4)} />
                                                    {/* <Image src={item} fallback={<Breathing height={190} />} /> */}
                                                </Box>
                                            </Col>
                                        ))}

                                        <FsLightbox
                                            toggler={toggler}
                                            // sources={list.list}
                                            // sourceIndex={index}
                                            sources={tabs[activeItem].list} // Use the sources of the active tab
                                            sourceIndex={clickedImageIndex}
                                            onSlideChange={handleSlideChange}
                                            onClose={closeGallery}

                                        />
                                    </Row>
                                </Box>
                            ))}
                        </Box>
                    </Col>
                </Row>
            </Container>
        </GalleryWrapper >
    )
}

export default GalleryComponent