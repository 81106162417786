import React from 'react'
import { ProcessStepsWrapper } from './Styled'
import { serviceMaintaneData } from '../../data/Data'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading } from '../../Styled/Elements'
import { DownArrow, MaintaneBg, MaintanePlant, ProcessStepsBg, StepArrow1, StepArrow2, StepArrow3, StepArrow4, StepImg1, StepImg2, StepImg3, StepImg4 } from '../../Styled/AllImages'
import ContactForm from '../contactProcess/ContactForm'
import { StepNumbers } from '../../Styled/CustomElements'

const ProcessSteps = () => {
    const { t } = useTranslation();

    return (
        <ProcessStepsWrapper className='common-space bg-setting' style={{ background: `url(${ProcessStepsBg})` }}>
            <Container>
                
                <Row>
                    <Col className='position-relative'>
                        <Box className='d-flex align-items-center justify-content-center my-4 fiveStepH'>
                            <MainHeading className='main-heading headingProcessCon' color='var(--themeColor)'><span>5</span> {t('fiveEasy')}</MainHeading>
                        </Box>
                        <Box className='processFormWrapper'>
                            <Box className="processFormHeadingContainer">
                                <Box className="processFormContainer">
                                    <ContactForm />
                                </Box>
                                <Box className='d-flex align-items-center justify-content-center my-4 fiveStepH2'>
                                    <MainHeading className='main-heading headingProcessCon' color='var(--themeColor)'><span>5</span> {t('fiveEasy')}</MainHeading>
                                </Box>
                            </Box>
                            <Box className='stepsOne'>
                                <StepNumbers number="01" stepTxt={t('Tell_Vision')} />
                            </Box>
                            <Box className="stepContactWrapper">
                                <Box className='stepContact stepsTwo'>
                                    <StepArrow1 className='stepArrows stepArrowOne' />
                                    <Box className='stepNumImg'>
                                        <StepNumbers number="02" stepTxt={t('Consultation_Visit')} class="stepNumbersWrapTwo" />
                                        {/* <LazyImage src={StepImg1} className="stepImgRound" /> */}
                                    </Box>
                                </Box>
                                <Box className='stepContact stepsThree'>
                                    <StepArrow2 className='stepArrows stepArrowTwo' />
                                    <Box className='stepNumImg'>
                                        <StepNumbers number="03" stepTxt={t('Design_Phase')} class="stepNumbersWrapThree" />
                                        {/* <LazyImage src={StepImg2} className="stepImgRound" /> */}
                                    </Box>
                                </Box>
                                <Box className='stepContact stepsFour'>
                                    <StepArrow3 className='stepArrows stepArrowThree' />
                                    <Box className='stepNumImg'>
                                        <StepNumbers number="04" stepTxt={t('Proposal')} class="stepNumbersWrapFour" />
                                        {/* <LazyImage src={StepImg3} className="stepImgRound" /> */}
                                    </Box>
                                </Box>
                                <Box className='stepContact stepsFive'>
                                    <StepArrow4 className='stepArrows stepArrowFour' />
                                    <Box className='stepNumImg'>
                                        <StepNumbers number="05" stepTxt={t('Build_Phase')} class="stepNumbersWrapFive" />
                                        {/* <LazyImage src={StepImg4} className="stepImgRound" /> */}
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Col>
                </Row>
            </Container>
            {/* <Box className='plant'>
                <LazyImage src={MaintanePlant} />
            </Box> */}
            <Box className='down-btn down-btn-all down-btn-steps'>
                <DownArrow className='arrow-down-sub' />
            </Box>
        </ProcessStepsWrapper>
    )
}

export default ProcessSteps