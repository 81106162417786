import { Maintane1, Maintane2, Maintane3, Maintane4, Maintane5, Maintane6, Service1, Service2, Service3, Service4, Service5, Service6, Service7, Service8 } from "../Styled/AllImages";
import { LazyImage } from "../Styled/Elements";


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'vision',
        label: 'Vision',
    },
    {
        to: 'services',
        label: 'Services',
    },
    {
        to: 'projects',
        label: 'Projects',
    },
    // {
    //     to: 'Client',
    //     label: 'Client',
    // },

    {
        to: 'contact',
        label: 'contactUs',
    },
]

// export const serviceListData = [
//     {
//         icon: <ServiceItem1 />,
//         subHeading: "Landscape",
//         MainHeading: "Architecture",
//         listIcon: <ServiceArrow />,
//         text1: "Construction1",
//         text2: "Site_Analysis",
//         text3: "Landscaping_Rendering",
//         text4: "Architectural_Structures",
//     },
//     {
//         icon: <ServiceItem2 />,
//         subHeading: "Hardscape",
//         MainHeading: "Electromechanical",
//         listIcon: <ServiceArrow />,
//         text1: "Sitting_Areas_Walkside",
//         text2: "Side_Walk_for_Gardens",
//         text3: "BBQ_Area_Outdoors",
//         text4: "Concrete_Docks",
//     },
//     {
//         icon: <ServiceItem3 />,
//         subHeading: "Fountain",
//         MainHeading: "Works",
//         listIcon: <ServiceArrow />,
//         text1: "Wall_Fountains",
//         text2: "Dancing_Fountains",
//         text3: "Modern_Fountains",
//         text4: "Classic_Fountains",
//     },
//     {
//         icon: <ServiceItem4 />,
//         subHeading: "Irrigation",
//         MainHeading: "System",
//         listIcon: <ServiceArrow />,
//         text1: "Drip_Irrigation",
//         text2: "Sprinkler_Irrigation",
//         text3: "Center_Irrigation",
//         text4: "Terraced_Irrigation",
//     },
// ]

export const serviceMaintaneData = [
    {
        icon: <Maintane1 />,
        subHeading: "Trimming_Pruning",
    },
    {
        icon: <Maintane2 />,
        subHeading: "Spraying_Insecticides",
    },
    {
        icon: <Maintane3 />,
        subHeading: "Removing_Weeds",
    },
    {
        icon: <Maintane4 />,
        subHeading: "Ensuring",
    },
    {
        icon: <Maintane5 />,
        subHeading: "Soil",
    },
    {
        icon: <Maintane6 />,
        subHeading: "Cleaning",
    },
]
export const projectList = [
    {
        // img: <LazyImage src={ProjectImg1} />,
        heading: 'Cafe_Landscape',
        subHeading: 'Design',
    },
    {
        // img: <LazyImage src={ProjectImg2} />,
        heading: 'Namq',
        subHeading: 'Design',
    },
    {
        // img: <LazyImage src={ProjectImg3} />,
        heading: 'Rasin',
        subHeading: 'Construction',
    },
    {
        // img: <LazyImage src={ProjectImg1} />,
        heading: 'Cafe_Landscape',
        subHeading: 'Design',
    },

]


export const serviceThumbData = [
    {
        // img: <img src={Services1} alt="Service" />,
        img:<Service1/>,
        surTitle: "Architectural_Designs",
    },
    {
        // img: <img src={Services2} alt="Service" />,
        img:<Service2/>,
        surTitle: "Pools_Fountains",
    },
    {
        // img: <img src={Services3} alt="Service" />,
        img:<Service3/>,
        surTitle: "Green_Areas_Development",
    },
    {
        // img: <img src={Services4} alt="Service" />,
        img:<Service4/>,
        surTitle: "Outdoor_Seating",
    },
    {
        // img: <img src={Services5} alt="Service" />,
        img:<Service5/>,
        surTitle: "Electromechanical_Works",
    },
    {
        // img: <img src={Services6} alt="Service" />,
        img:<Service6/>,
        surTitle: "Shades_and_Service_Areas",
    },
    {
        img:<Service7/>,
        surTitle: "Barbecue_Areas",
    },
    {
        img:<Service8/>,
        surTitle: "Outdoor_Lounges",
    },
]

export const tabs = [
    {
        id: 'GreenThumb',
        title: 'Green_Thumb',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935465/deyem-projects/nursery/20231115_105615211_iOS_cdryzb.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935465/deyem-projects/nursery/20231202_142702463_iOS_jq5nq9.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935468/deyem-projects/nursery/20231112_073000000_iOS_mikq3x.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935469/deyem-projects/nursery/20231202_142850170_iOS_h4dlya.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935478/deyem-projects/nursery/20231202_142723078_iOS_soid0y.jpg",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713337943/deyem-projects/nursery/20231021_125102000_iOS_br3t57_ccfiwx.mp4",
        ],
    },


];
export const projectsTabs = [
    {
        id: 'DIRYAHCity',
        title: 'DIRYAH_City',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713787725/deyem-projects/diryah-real/IMG_1530_02-01-2024_23_33_59_445_ch9o8w.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713787727/deyem-projects/diryah-real/IMG_1533_ktuuef.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713787729/deyem-projects/diryah-real/IMG_1536_vxejfq.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713787729/deyem-projects/diryah-real/IMG_1532_02-01-2024_23_33_48_773_cruo18.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713787730/deyem-projects/diryah-real/IMG_1559_nbhqzp.jpg",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713787915/deyem-projects/diryah-real/IMG_1560_crxo4d.mp4",
        ],
    },
    {
        id: 'SADU',
        title: 'SADU_21',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935763/deyem-projects/sadu/IMG_3098_sxuebw.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935767/deyem-projects/sadu/IMG_3064_huemez.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935768/deyem-projects/sadu/IMG_3070_vbtnan.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935769/deyem-projects/sadu/IMG_3089_rv1p8g.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935773/deyem-projects/sadu/IMG_3034_idmnpq.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713935777/deyem-projects/sadu/IMG_3037_hgmm1h.jpg",
            // "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        ],

    },
    {
        id: 'MassifVillas',
        title: 'Massif_Villas',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936078/deyem-projects/massfi-viila/Villa_K.Fahad_Landscape_page-0003_sud7ll.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936097/deyem-projects/massfi-viila/Villa_K.Fahad_Landscape_page-0007_psncb8.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936097/deyem-projects/massfi-viila/Villa_K.Fahad_Landscape_page-0006_fpojrj.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936105/deyem-projects/massfi-viila/Villa_K.Fahad_Landscape_page-0002_v5dfbq.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936107/deyem-projects/massfi-viila/IMG_1476_fiy6ln.jpg",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713936109/deyem-projects/massfi-viila/IMG_1459_tdyrtt.mov",
        ],
    },
    {
        id: 'FramLand',
        title: 'Fram_Land',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936392/deyem-projects/framLand/IMG_2009_avc9v2.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936395/deyem-projects/framLand/IMG_1994_sf5olq.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936402/deyem-projects/framLand/IMG_1959_xipiu3.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936408/deyem-projects/framLand/IMG_1970_wtibqa.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713936427/deyem-projects/framLand/IMG_1995_hmnvb4.jpg",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713936438/deyem-projects/framLand/IMG_1992_wgjb4q.mov",
        ],
    },
    {
        id: 'NajdResident',
        title: 'Najd_Resident',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713334466/deyem-projects/najd/Screenshot_2024-04-16_195956_qtp5eg_yzha57.png",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713334487/deyem-projects/najd/Screenshot_2024-04-16_200034_gnhqll_kmjkrj.png",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713334520/deyem-projects/najd/Screenshot_2024-04-16_200151_j8xwdh_xseywp.png",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713334531/deyem-projects/najd/Screenshot_2024-04-16_200324_pu7rte_kgxouj.png",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713351748/deyem-projects/najd/Screenshot_2024-04-17_160118_gy3fqk.png",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713334450/deyem-projects/najd/20230905_093755000_iOS_nzbqiv_dmnzyh.mp4",
        ],

    },
    {
        id: 'Roshen',
        title: 'Roshen_389',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713942711/deyem-projects/roshen/20230919_121439425_iOS_basljz.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713942634/deyem-projects/roshen/video-thumb-5_rkq4ax.png",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713942650/deyem-projects/roshen/Screenshot_2024-04-24_103901_l3ogkm.png",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713942673/deyem-projects/roshen/Screenshot_2024-04-24_104026_upxnx0.png",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713942627/deyem-projects/roshen/Screenshot_2024-04-24_103738_wfe2nd.png ",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713942805/deyem-projects/roshen/20230919_121606000_iOS_jafih8.mov",
        ],
    },
    {
        id: 'SAUDIPOLO',
        title: 'SAUDI_POLO',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713937652/deyem-projects/roshen/IMG_4428_quem9e.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713937655/deyem-projects/roshen/%D8%AA%D9%86%D8%B2%D9%8A%D9%84_itwpd8.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713937872/deyem-projects/roshen/photo1703424247_xfgko7.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713937867/deyem-projects/roshen/photo1703424216_fmlmwd.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713937678/deyem-projects/roshen/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_1_iya0dt.jpg",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713937674/deyem-projects/roshen/IMG_4454_hvzyzb.mov",
        ],
    },
    {
        id: 'CafesLandscape',
        title: 'Cafes_Landscape',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713938013/deyem-projects/cafe-landscape/photo1703528451_6_y7drvd.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713938029/deyem-projects/cafe-landscape/photo1703528451_1_kxfncz.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713938037/deyem-projects/cafe-landscape/photo1703528451_2_bm9zn8.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713938046/deyem-projects/cafe-landscape/photo1703528451_3_p5el7i.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713938055/deyem-projects/cafe-landscape/photo1703528451_4_mrabqe.jpg",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713938020/deyem-projects/cafe-landscape/IMG_1668_p9k9tf.mp4",
        ],
    },
    {
        id: 'AmaariahVilla',
        title: 'Amaariah_Villa',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713938929/deyem-projects/amaariah-villa/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_ihrhjt.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713938950/deyem-projects/amaariah-villa/photo1703264341_fksk5w.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713938956/deyem-projects/amaariah-villa/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_2_pkojwu.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713939775/deyem-projects/amaariah-villa/photo1703263942_bfnbhf.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713939799/deyem-projects/amaariah-villa/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_1_e074lu.jpg",
            "https://res.cloudinary.com/dsqjzmey8/video/upload/v1713938939/deyem-projects/amaariah-villa/IMG_1642_d2zu2i.mp4",
        ],
    },
    {
        id: 'WAKAN18',
        title: 'WAKAN_18',
        list: [
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713939986/deyem-projects/wakan/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_6_jhnlp1.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713939991/deyem-projects/wakan/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_3_suxlnj.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713940088/deyem-projects/wakan/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_9_e3bnxc.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713940511/deyem-projects/wakan/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_7_kzofpb.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713940533/deyem-projects/wakan/%D9%88%D8%A7%D8%AC%D9%87%D8%A7%D8%AA_THE_MASS__page-0001_cwocdd.jpg",
            "https://res.cloudinary.com/dsqjzmey8/image/upload/v1713940516/deyem-projects/wakan/%D8%AD%D9%81%D8%B8_%D8%AD%D9%82%D9%88%D9%82_%D8%A7%D9%84%D9%85%D8%B4%D8%A7%D8%B1%D9%8A%D8%B9_5_ou1vpc.jpg",
            // "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        ],
    },

    // {
    //     id: 'underimplementation',
    //     title: 'Under Implementation',
    //     list: [
    //         "https://i.imgur.com/fsyrScY.jpg",
    //         "https://source.unsplash.com/Dm-qxdynoEc/800x799",
    //         "https://source.unsplash.com/qDkso9nvCg0/600x799",
    //         "https://source.unsplash.com/iecJiKe_RNg/600x799",
    //         "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
    //         "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    //     ],
    // },

];