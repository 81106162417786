import styled from "styled-components";

export const ProjectWrapper = styled.section`
position:relative;
.projects-content{
    // display: inline-block;
    padding:50px 20px;
    text-align:center;
}
.slick-next {
    right: -30px;
    // left: auto;
}
.slick-prev {
    left: -45px ;
    // right: auto;
}
.slick-prev, .slick-next {
    width:45px;
    height:45px;
    top:45%;

}



`
export const ProjectGalleryWrapper = styled.section`

position:relative;
.tab-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--text);
    border-top: 1px solid var(--text);
    gap:15px;
    &>svg{
        
        width: 45px;
        height: 45px;
    }
}
.tab-content{
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
    overflow-x: auto;
    white-space: nowrap;
    transition: scroll-left 0.4s ease-in;
    gap: 15px;
    width: 100%;
    &::-webkit-scrollbar {
        display:none;
    }
}
.tabs-item{
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    background: transparent;
    color:var(--text);
    
    &:hover{
        border-radius: 8px;
        background: var(--text);
        color:var(--themeColor);
    } 
    &.active{
        border-radius: 8px;
        background: var(--text);
        color:var(--themeColor);
    }
}
.Tab-box{
    margin:10px 0;
    position:relative;
    
    & span{
        position:relative;
        width: 100%;
        height:190px;
        filter: none;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 190px;
            top: 0;
            left: 0;
            background: #8080805c;
            border-radius: 10px;
        }
    }
    
}
.Tab-box .custom-img{
    border-radius:12px;
    width:100%;
    height:100%;
    object-fit:cover;
    z-index: 2;
    position: relative;
}
.tab-box-content{
    // width: 40%;
    padding: 70px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.project-layer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.project-theme-design{
    position: absolute;
    top: -100px;
    transform: scale(-1);
    -webkit-transform: scale(-1);
    -moz-transform: scale(-1);
    -ms-transform: scale(-1);
    -o-transform: scale(-1);
}
.border-bottom-line:before{
    background-color:#E3E3E3;
    width:100%;
    bottom:-5px;
}

.react-photo-gallery--gallery{
    & >div{
        align-items: center;
        justify-content: space-between;
        gap:20px;
        // width: 300px;
        height: 225px;
    }

    & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius:12px;
    }
}


`
