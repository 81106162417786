import styled from "styled-components";

export const ContactWrapper = styled.section`
position:relative;
.bg-setting{
 border-radius:12px;
 position:relative;
}
.contact-wrapper{
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
    padding:0 20px 20px;
}
.contect-with-us{
    width:50%;
}
.contect-with-you{
    width:40%;
}
.contact-theme{
    position: absolute;
    height: 225px;
    top: -100px;
    bottom: 0px;
    margin: auto;
    z-index: 1;
}
.contact-right{
    right: 0;
}
.contact-left{
    left: 0;
    -webkit-transform: scale(-1);
    -moz-transform: scale(-1);
    -ms-transform: scale(-1);
    -o-transform: scale(-1);
}
.contact-box{
    padding:50px;
    padding-bottom: 0;
    iframe{
        // filter: grayscale(100%);
        filter: invert(90%);
    }
}
#contact{
    padding-top: 50px;
}
.contact-svg >svg {
    [fill]{
        fill:var(--text);
    }
}
.contact-text{
    color:var(--text);
}
.contact-info{
    position: relative;
    margin: 0 0 20px 0;
    &::before{
        content:"";
        position:absolute;
        width: 250px;
        height: 1px;
        background: #095F34;
        bottom: -20px;
    }
}
.contact-links svg{
    width :24px;
    height:24px;
    cursor: pointer;
    [fill]{
        fill:var(--text);
    }

}
.contact-img{
    position:absolute;
    right:0;
    bottom:25%;
}
    
`
export const ContactFormWrapper = styled.div`
    width:90%;
.field-box{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin:20px 0;
}
.input-field{
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    color:var(--white);
    background: #095F34;
    box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    -webkit-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    -moz-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);

}

.input-field::placeholder{
    color:var(--textPalceholder);
}
.error-message{
    font-family:var(--arRegular);
    // border:1px solid red;
    color:red;
    padding:6px 8px;
    border-radius:8px;
    background-color: #ff000014;
}
.loading-btn{
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--whiteColor);
    font-family:var(--regular);

}
.contact-social-links{
    position: relative;
    left: 15px;
}

`