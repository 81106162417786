import React from 'react'
import { ServiceWrapper } from './Styled'
import { serviceListData, serviceThumbData } from '../../data/Data'
import { Col, Container, Row, } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { BTNICON, DownArrow, ServiceBg, ServiceTrees, Services1 } from '../../Styled/AllImages'
import { Box, HeadingStyled, LazyImage, MainHeading, Span, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import ServiceMaintane from './ServiceAchive'

const Service = () => {
    const { t, i18n } = useTranslation();

    const WhatsApp = () => {
        const phoneNumber = '+966538343390';
        const message = 'Hello, deyam Landscape ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <Box id='services'>
            <ServiceWrapper className='bg-setting' style={{ background: `url(${ServiceBg})` }} >
                <Container>
                    <Row>
                        <Col>
                            <Box className='d-flex align-items-center justify-content-center common-space'>
                                <MainHeading className='main-heading' color='var(--white)'>{t('Our_Services')}</MainHeading>
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        {serviceThumbData.map((list, key) => (
                                <Col lg={3} md={6} sm={12} key={key} className='mb-4'>
                                    
                                    <Box className='servicesContent'>
                                        {list.img}
                                        <HeadingStyled size="16px" color="var(--text)" className='servicesText mt-2'>{t(list.surTitle)}</HeadingStyled>
                                    </Box>
                                </Col>
                            ))
                        }
                    </Row>

                    <Box className='d-flex align-items-center justify-content-center mt-4'>
                        <ContactButton className='button1 my-4' icon={BTNICON} label={t('button')} onClick={WhatsApp} />
                    </Box>
                </Container>
                <Box className='service-tree'>
                    <LazyImage className='position-relative w-100' src={ServiceTrees} />
                </Box>
                <Box className='down-btn down-btn-all down-btn-services'>
                    <DownArrow className='arrow-down' />
                </Box>
            </ServiceWrapper >

            <ServiceMaintane />
        </Box>
    )
}

export default Service