import styled from "styled-components";
import { Attach } from "../../Styled/AllImages";

export const ContactFormWrapper = styled.div`
    width:100%;
.field-box{
    display: flex;
    flex-direction: column;
    gap:0px;
    margin:10px 0;
    width: 100%;
}
.input-field{
    padding: 8px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    color: var(--themeColor);
    width: 100%;
    background: var(--white);
    border: 1px solid var(--text)
    // box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    // -webkit-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    // -moz-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);

}

.input-field::placeholder{
    color:var(--textPalceholder);
}
.error-message{
    font-family:var(--arRegular);
    // border:1px solid red;
    color:red;
    padding:6px 8px;
    border-radius:8px;
    background-color: #ff000014;
}
.loading-btn{
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--whiteColor);
    font-family:var(--regular);

}
.contact-social-links{
    position: relative;
    left: 15px;
}
.proSetBtn, .proSetBtn button{
    width: 100%;
    justify-content: center;
}

input[type="file"] {
    position: relative;
    border:1px dashed var(--text);
  }
  input.input-field[type="file"]{
    padding: 10px 0 8px 0;
  }
  input[type="file"]::file-selector-button {
    width: 170px;
    color: transparent;
    background-color: transparent;
    border: none;
  }
  
  /* Faked label styles and icon */
  input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    top: 11px;
    left: 15px;
    height: 20px;
    width: 20px;
    content: "";
    background-image: url(${Attach});
    background-repeat: no-repeat;
    
  }
  
  input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    top: 11px;
    left: 40px;
    color: var(--textPalceholder);
    content: "Add Attachment";
  }

`

