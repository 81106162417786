import styled from "styled-components";

export const ProcessStepsWrapper = styled.section`

    .processFormWrapper{
        position: relative;
        padding-top: 100px;
        padding-bottom: 810px;
    }
    .processFormHeadingContainer{
        display: flex;
        width: 100%;
        gap: 80px;
        align-items: flex-start;
    }
    .processFormContainer{
        displa: flex;
        background-color: var(--white);
        padding: 21px 24px 24px 24px;
        max-width: 460px;
        min-width: 460px;
        border-radius: 12px;
        position: relative;
        z-index: 2;
       
    }
    .field-box-wh{
        display: flex;
        width: 100%;
        gap: 16px;
    }
.headingProcessCon{
    padding-right: 200px;
}

`
