import React from 'react'
import { AchivementWrapper } from './Styled'
import { serviceMaintaneData } from '../../data/Data'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading } from '../../Styled/Elements'
import { MaintaneBg, MaintanePlant } from '../../Styled/AllImages'

const ServiceMaintane = () => {
    const { t } = useTranslation();

    return (
        <AchivementWrapper className='common-space bg-setting' style={{ background: `url(${MaintaneBg})` }}>
            <Container>
                <Row>
                    <Col>
                        <Box className='d-flex align-items-center justify-content-center my-4'>
                            <MainHeading className='main-heading' color='var(--themeColor)'>{t('Maintenance')}</MainHeading>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    {
                        serviceMaintaneData.map((list, key) => (
                            <Col lg={4} md={6} sm={12}>
                                <Box key={key} className='d-flex flex-column align-items-center justify-content-center gap-2 mb-5'>
                                    {list.icon}
                                    <Box>
                                        <HeadingStyled className='text-center' lh='1' size='22px' transform='math-auto' color='var(--themeColor)'>{t(list.subHeading)}</HeadingStyled>
                                    </Box>
                                </Box>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
            <Box className='plant'>
                <LazyImage src={MaintanePlant} />
            </Box>
        </AchivementWrapper>
    )
}

export default ServiceMaintane