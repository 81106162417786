import React from 'react'
import { AboutValueWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { BTNICON, Bird, DownArrow, VisionImg } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { ContactButton2 } from '../../Styled/CustomElements'

const AboutValues = () => {
    const WhatsApp = () => {
        const phoneNumber = '+966538343390';
        const message = 'Hello, deyam Landscape ';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    return (
        <AboutValueWrapper id='vision'>
            <Container>
                <Row className='align-items-center common-space visionRow'>
                    <Col lg={6} md={12}>
                        <Box className='d-flex flex-column gap-4 visionBox'>
                            <MainHeading className='main-heading' color='var(--themeColor)'> {t('Vision_of_Future')}</MainHeading>
                            <TextStyled color='var(--themeColor)'>
                                <SpanStyled color='var(--themeColor)'> {t('Vision_of_Future_heading')} </SpanStyled>
                                {t('Vision_of_Future_Content')}
                            </TextStyled>
                            {/* <TextStyled color='var(--white)'>
                                <SpanStyled color='var(--white)'> {t('seek')}, </SpanStyled>
                                {t('seek_content')}
                            </TextStyled> */}
                            <ContactButton2 className='my-3' icon={BTNICON} label={t('button')} onClick={WhatsApp} />
                        </Box>
                    </Col>
                    <Col lg={6} md={12}>
                        <Box className='text-end'>
                            <LazyImage className='vision-img' src={VisionImg} />
                        </Box>
                    </Col>
                </Row>
                <Box className='bird-wrap'>
                    <LazyImage className='bird' src={Bird} />
                </Box>
            </Container>
            <Box className='down-btn down-btn-all down-btn-about'>
                <DownArrow className='arrow-down' />
            </Box>
        </AboutValueWrapper >
    )
}

export default AboutValues